<template>
    <div class="page-wrapper" id="scroll_top">
        <section class="hero_content" v-if="pageIsLoaded">
            <div class="data">
                <h2 class="title small" data-inview="fadeInUp" data-delay="200">
                    {{ $t('Réalisations') }}
                </h2>
                <h1 class="title medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.titreSimple"></span>
                </h1>
                <div class="legend" data-inview="fadeInUp" data-delay="400">
                    <div class="pin">
                        <img src="@/assets/img/pin-1.svg" :alt="$t('Projets terminés')" />
                        <p class="regular-text">{{ $t('Projets terminés') }}</p>
                    </div>
                    <div class="pin">
                        <img src="@/assets/img/pin-2.svg" :alt="$t('Projets en cours')" />
                        <p class="regular-text">{{ $t('Projets en cours') }}</p>
                    </div>
                </div>
            </div>
            <div class="img" data-inview="revealRight" data-delay="200">
                <img class="ph" src="@/assets/img/map.jpg" alt="Services St-Denis Thompson" />
                <div id="map"></div>
            </div>
            <a href="#next" v-scroll-to:-120
                ><svg
                    fill="none"
                    class="arrow"
                    viewBox="0 0 13 26"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="m11.6412 17.9322-4.17083 4.2832v-22.2154h-1.94081v22.2154l-4.17082-4.2832-1.35874 1.395 6.5 6.6728 6.5-6.6728z"
                    /></svg
            ></a>
        </section>

        <!-- Scemantic Data for Map -->
        <div class="map-places" v-if="globalsIsLoaded">
            <div
                v-for="(item, i) in globals.realisationsEntry.data"
                :key="i"
                class="point place"
                :data-place-lat="item.page.geoadresse.lat"
                :data-place-lng="item.page.geoadresse.lng"
                :data-place-name="item.page.titre"
                :data-place-address="item.page.client"
                :data-place-url="item.page.jsonUrl ? item.page.jsonUrl : '' "
                :data-place-etat="item.page.statusDuProjet ? 'termine' : 'encours'"
                :data-place-img="item.page.imageSimple1.url"
            ></div>
        </div>

        <form action="" @submit.prevent="filterPage()" class="form_filters" id="next" v-if="globalsIsLoaded">
            <input type="text" :placeholder="$t('Recherche')" class="input search" v-model="search" />

            <select class="input select" v-model="service" @change="filterPage()">
                <option value="">{{ $t('Service') }}</option>
                <option :value="globals.servicesEntry.data[0].page.slug">
                    {{ globals.servicesEntry.data[0].page.titre }}
                </option>
                <option :value="globals.servicesEntry.data[1].page.slug">
                    {{ globals.servicesEntry.data[1].page.titre }}
                </option>
                <option :value="globals.servicesEntry.data[2].page.slug">
                    {{ globals.servicesEntry.data[2].page.titre }}
                </option>
            </select>

            <select class="input select" v-model="secteur" @change="filterPage()">
                <option value="">{{ $t('Secteur') }}</option>
                <option :value="item.slug" v-for="(item, i) in content.categoriesSecteurs" :key="i">{{ item.titre }}</option>
            </select>
        </form>

        <section class="project_listing small-margin-top" v-if="pageIsLoaded">
            <div class="row">
                <router-link
                    :to="item.page.jsonUrl"
                    class="project"
                    data-inview="fadeInUp"
                    :data-delay="2 * 1 + '00'"
                    v-for="(item, i) in realisationsChunks[0]"
                    :key="i"
                >
                    <div class="img">
                        <div class="more">
                            <p class="regular-text">{{ $t('En savoir plus') }}</p>
                        </div>
                        <img
                            :src="item.page.imageSimple1.url"
                            :alt="item.page.imageSimple1.titre"
                        />
                    </div>
                    <div class="data">
                        <p class="title">{{ item.page.titre }}</p>
                    </div>
                </router-link>
            </div>

            <div class="row">
                <router-link
                    :to="item.page.jsonUrl"
                    class="project"
                    data-inview="fadeInUp"
                    :data-delay="2 * 1 + '00'"
                    v-for="(item, i) in realisationsChunks[1]"
                    :key="i"
                >
                    <div class="img">
                        <div class="more">
                            <p class="regular-text">{{ $t('En savoir plus') }}</p>
                        </div>
                        <img
                            :src="item.page.imageSimple1.url"
                            :alt="item.page.imageSimple1.titre"
                        />
                    </div>
                    <div class="data">
                        <p class="title">{{ item.page.titre }}</p>
                    </div>
                </router-link>
            </div>

            <div
                class="pagination_wrap"
                data-inview="fadeInUp"
                data-delay="200"
                v-if="pagination.total_pages > 1"
            >
                <a href="#" class="cta switch" v-if="pagination.current_page !== 1" @click.prevent="searchAndFilterResults(service, secteur, search, pagination.current_page - 1)">
                    <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                    <p class="regular-text">{{ $t('Page Précédente') }}</p>
                </a>
                <p class="regular-text">
                    {{ pagination.current_page }} / {{ pagination.total_pages }}
                </p>
                <a href="#" class="cta" v-if="pagination.current_page < pagination.total_pages" @click.prevent="searchAndFilterResults(service, secteur, search, pagination.current_page + 1)">
                    <p class="regular-text">{{ $t('Page suivante') }}</p>
                    <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                </a>
            </div>

            <p class="regular-text" v-if="!isLoading && (!realisationsList.length || !realisationsChunks[0].length)">
                {{ $t('Aucun résultat pour cette recherche.') }}
            </p>

            <div class="page-loader" v-if="isLoading">
                <svg
                    class="simple-page-loader"
                    width="78"
                    viewBox="0 0 38 38"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#596778"
                >
                    <g transform="translate(1 1)" stroke-width="1" fill="none" fill-rule="evenodd">
                        <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                        <path d="M36 18c0-9.94-8.06-18-18-18">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 18 18"
                                to="360 18 18"
                                dur="1s"
                                repeatCount="indefinite"
                            />
                        </path>
                    </g>
                </svg>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import GLightbox from 'glightbox'

import axios from 'axios'

import { initInViewAnimations } from '@/plugins/AnimationsLayer'

// import Flickity from 'flickity'

export default {
    data() {
        return {
            lightboxelement: null,
            realisationsList: [],
            isLoading: true,
            pagination: {
                total: 1,
                count: 1,
                per_page: 8,
                current_page: 1,
                total_pages: 1,
            },
            search: '',
            service: '',
            secteur: '',
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.lightboxelement = GLightbox
                        GLightbox()

                        const scriptId = 'mapInitEl'
                        const mapAlreadyAttached = !!document.getElementById(scriptId)

                        if (mapAlreadyAttached) {
                            if (window.google) {
                                this.initGoogleMap()
                            }
                        } else {
                            window.mapApiInitialized = this.initGoogleMap

                            const script = document.createElement('script')
                            script.id = scriptId
                            script.src =
                                'https://maps.googleapis.com/maps/api/js?key=AIzaSyBqI-I62_T6zmJ7LtXNo4nT9ajaDf_-n_s&libraries=places,geometry&callback=mapApiInitialized'
                            document.body.appendChild(script)
                        }

                        setTimeout(() => {
                            // this.teamCarousel.resize()
                        }, 1755)
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        realisationsChunks() {
            if (!this.realisationsList.length) {
                return [[], []]
            }
            // console.log(this.realisationsList);
            // Take 8 items from realisationsEntry data array
            const realisationsArray = this.realisationsList

            // Create 2 arrays with the array
            const realisationsChunks = []

            if (realisationsArray.length === 1) {
                const chunkSize = 1
                for (let i = 0; i < realisationsArray.length; i += chunkSize) {
                    const chunk = realisationsArray.slice(i, i + chunkSize)
                    realisationsChunks.push(chunk)
                }
            }

            if (realisationsArray.length === 2) {
                const chunkSize = 1
                for (let i = 0; i < realisationsArray.length; i += chunkSize) {
                    const chunk = realisationsArray.slice(i, i + chunkSize)
                    realisationsChunks.push(chunk)
                }
            }

            if (realisationsArray.length === 3) {
                const chunkSize = 2
                for (let i = 0; i < realisationsArray.length; i += chunkSize) {
                    const chunk = realisationsArray.slice(i, i + chunkSize)
                    realisationsChunks.push(chunk)
                }
            }

            if (realisationsArray.length === 4) {
                const chunkSize = 2
                for (let i = 0; i < realisationsArray.length; i += chunkSize) {
                    const chunk = realisationsArray.slice(i, i + chunkSize)
                    realisationsChunks.push(chunk)
                }
            }

            if (realisationsArray.length === 5) {
                const chunkSize = 3
                for (let i = 0; i < realisationsArray.length; i += chunkSize) {
                    const chunk = realisationsArray.slice(i, i + chunkSize)
                    realisationsChunks.push(chunk)
                }
            }

            if (realisationsArray.length >= 6) {
                const chunkSize = 4
                for (let i = 0; i < realisationsArray.length; i += chunkSize) {
                    const chunk = realisationsArray.slice(i, i + chunkSize)
                    realisationsChunks.push(chunk)
                }
            }

            return realisationsChunks
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        initGoogleMap() {
            /* global google */
            const mapOptions = {
                // How zoomed in you want the map to start at (always required)
                zoom: 15,

                // The latitude and longitude to center the map (always required)
                center: new google.maps.LatLng(45.47006, -73.58204),

                // How you would like to style the map.
                // This is where you would paste any style found on Snazzy Maps.
                styles: [
                    {
                        featureType: 'water',
                        elementType: 'geometry',
                        stylers: [{ color: '#e9e9e9' }, { lightness: 17 }],
                    },
                    {
                        featureType: 'landscape',
                        elementType: 'geometry',
                        stylers: [{ color: '#f5f5f5' }, { lightness: 20 }],
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.fill',
                        stylers: [{ color: '#ffffff' }, { lightness: 17 }],
                    },
                    {
                        featureType: 'road.highway',
                        elementType: 'geometry.stroke',
                        stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }],
                    },
                    {
                        featureType: 'road.arterial',
                        elementType: 'geometry',
                        stylers: [{ color: '#ffffff' }, { lightness: 18 }],
                    },
                    {
                        featureType: 'road.local',
                        elementType: 'geometry',
                        stylers: [{ color: '#ffffff' }, { lightness: 16 }],
                    },
                    {
                        featureType: 'poi',
                        elementType: 'geometry',
                        stylers: [{ color: '#f5f5f5' }, { lightness: 21 }],
                    },
                    {
                        featureType: 'poi.park',
                        elementType: 'geometry',
                        stylers: [{ color: '#dedede' }, { lightness: 21 }],
                    },
                    {
                        elementType: 'labels.text.stroke',
                        stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }],
                    },
                    {
                        elementType: 'labels.text.fill',
                        stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }],
                    },
                    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
                    {
                        featureType: 'transit',
                        elementType: 'geometry',
                        stylers: [{ color: '#f2f2f2' }, { lightness: 19 }],
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'geometry.fill',
                        stylers: [{ color: '#fefefe' }, { lightness: 20 }],
                    },
                    {
                        featureType: 'administrative',
                        elementType: 'geometry.stroke',
                        stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }],
                    },
                ],
            }

            // Get the HTML DOM element that will contain your map
            // We are using a div with id="map" seen below in the <body>
            const mapElement = document.getElementById('map')

            // Create the Google Map using our element and options defined above
            const map = new google.maps.Map(mapElement, mapOptions)

            // For each place, get the icon, name and location.
            const markers = []
            const markerInfos = []
            const bounds = new google.maps.LatLngBounds()
            let boundsLength = 0
            const places = [].slice.call(document.querySelectorAll('.place'))
            places.forEach(function (place) {
                const placeLocation = new google.maps.LatLng(
                    place.getAttribute('data-place-lat'),
                    place.getAttribute('data-place-lng')
                )

                const icon = {
                    url:
                        place.getAttribute('data-place-etat') === 'termine'
                            ? '/static/img/pin-1.svg'
                            : '/static/img/pin-2.svg',
                }

                // Create a marker for each place.
                var newMarker = new google.maps.Marker({
                    map: map,
                    icon: icon,
                    title: place.getAttribute('data-place-name'),
                    position: placeLocation,
                })

                let shouldHideUrl = false
                if (!place.getAttribute('data-place-url')) {
                    shouldHideUrl = true
                }
                const markerInfo = new google.maps.InfoWindow({
                    content:
                        '<div id="content" class="google-marker-details ' +
                        place.getAttribute('data-place-etat') +
                        '"><img class="map-marker-img" src="' +
                        place.getAttribute('data-place-img') +
                        '" alt="projet"/><h3 id="map-place-heading" class="map-place-heading">' +
                        place.getAttribute('data-place-name') +
                        '</h3><p id="map-place-text" class="map-place-text">' +
                        place.getAttribute('data-place-address') +
                        '</p><a class="map-link ' +
                        (shouldHideUrl ? 'hide-url' : '') +
                        ' " href="' +
                        place.getAttribute('data-place-url') +
                        '" >Voir le projet</a></div>',
                })
                newMarker.addListener('mouseover', function () {
                    place.classList.add('is-active')
                })
                newMarker.addListener('mouseout', function () {
                    place.classList.remove('is-active')
                })
                newMarker.addListener('click', function () {
                    markerInfos.forEach(function (markerInfo) {
                        markerInfo.close()
                    })

                    markerInfo.open(map, newMarker)

                    markerInfos.push(markerInfo)

                })
                place.addEventListener('mouseenter', function (e) {
                    e.preventDefault()
                    markerInfos.forEach(function (markerInfo) {
                        markerInfo.close()
                    })
                    markerInfo.open(map, newMarker)
                    markerInfos.push(markerInfo)
                    newMarker.setAnimation(google.maps.Animation.BOUNCE)
                })
                place.addEventListener('mouseleave', function (e) {
                    e.preventDefault()
                    markerInfo.close()
                    newMarker.setAnimation(null)
                })
                markers.push(newMarker)

                // Map Bounds look at the first 4 only to prevent ultra zoomed-out map
                if (boundsLength < 4) {
                    bounds.extend(placeLocation)
                    boundsLength++
                }
            })
            map.fitBounds(bounds)
        },
        filterPage() {
            this.searchAndFilterResults(this.service, this.secteur, this.search, 1)
        },
        async searchAndFilterResults(service = '', secteur = '', s = '', page = 1, scrollToTop = true) {
            // .../api/realisationsEntryPaginated.json?service=batiments&secteur=type-1&s=Uni&page=1

            this.isLoading = true
            this.realisationsList = []

            const locale = this.$route.meta.lang === 'fr' ? '' : '/en'
            const baseUrl = '/api'
            const res = await axios.get(
                `${
                    process.env.VUE_APP_SITE_BASE_URL + locale + baseUrl
                }/realisationsEntryPaginated.json?service=${service}&secteur=${secteur}&s=${s}&page=${page}`
            )

            this.pagination = res.data.meta.pagination
            this.realisationsList = res.data.data

            this.isLoading = false

            setTimeout(() => {
                initInViewAnimations()

                if (scrollToTop) {
                    const topPos = document.querySelector('.project_listing').getBoundingClientRect().top + window.scrollY

                    // this changes the scrolling behavior to "smooth"
                    window.scroll({
                        top: topPos,
                        behavior: 'smooth',
                    })
                }
            }, 175)
        },
    },

    mounted() {
        this.searchAndFilterResults('', '', '', 1, false)
    },
}
</script>

<style lang="scss" scoped>

.page-loader {
    position: relative;
    width: 100%;
    text-align: center;
}
.img {
    position: relative;
}
.ph {
    display: block;
    width: 100%;
    z-index: 1;
}

#map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
</style>
